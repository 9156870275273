import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baddr: false,
    pbts: [],
    pbxs:[],
    pre_pbx:[],
    pbx_list_info:null,
    Auditor: null,
    Owner: null,
    User: null,
  },
  mutations: {
    setCoin(state, coin) {
      state.coin = coin
  },
    setBaddr(state, baddr) {
      state.baddr = baddr
  },
    setPBTs(state, pbts) {
      state.pbts = pbts
  },
    setPBXs(state, pbxs) {
      state.pbxs = pbxs
  },
    setPRE_PBX(state, pre_pbx) {
      state.pre_pbx = pre_pbx
  },
    setPBX_List_Info(state, pbx_list_info){
      state.pbx_list_info = pbx_list_info
  },
    setAuditor(state, auditor) {
      state.Auditor = auditor
  },  
    setOwner(state, owner) {
      state.Owner = owner
  }, 
    setUser(state, user) {
      state.User = user
  }, 
  },
  actions: {
  },
  modules: {
  }
})
