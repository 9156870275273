<template>
  <div id="app">
    <nav-bar/>
    <Content/>
  </div>
</template>

<script>
import NavBar from './components/NavBar'
import Content from './components/Content'

export default {
    name: 'app',
    components: {
      NavBar,
      Content
    }
}
</script>

<style>
</style>
