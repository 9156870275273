<template>
  <div>
    <div class="header">
      <div class="left-h">
        <img class="logo" src="../assets/logo.png" alt="PlotBride" />
        <span class="logo-name">PlotBridge</span> 
        <span class="version">&nbsp;&nbsp;v.1.0.1</span>
      </div>
      <div class="right-h">
        <el-button type="info" round v-if="!baddr" @click="connect_wallet"
          >连接钱包</el-button>
        <el-button type="info" round v-if="baddr" icon="el-icon-s-finance">{{ saddr }}</el-button>
        <el-button type="info" @click="logn_out" v-if="baddr" icon="el-icon-right" circle></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import wops from "../wallet";
import { mapState } from "vuex";

export default {
  name: "NavBar",
  components: {},
  data() {
    return {
      Auditor:null,
      Owner:null,
    };
  },
  computed: mapState({
    baddr: "baddr",
    xbalance: "xbalance",
    pre_pbx:"pre_pbx",
    saddr: function(){
      const a=this.baddr.substring(0,4)
      const b=this.baddr.substring(38,42)
      return a+"..."+b
    }
  }),
  methods: {
    logn_out: function() {
      window.location.reload()
    },
    connect_wallet: async function () {
      const loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading",
        background: "rgba(200, 230, 200, 0.7)",
      });
      try {
        const addr = await wops.connect();
        const commit = this.$store.commit;
        if (!addr) {
          this.$message(this.$t("connect-faild"));
        }else{
            commit('setBaddr',addr)
            commit("setUser", addr)
            commit('setPBTs', await wops.load_pbts())
            commit('setPBXs', await wops.load_pbxs())
            commit('setPRE_PBX',await wops.load_predefined_pbx())
        }
      } catch (e) {
        if (e.code === -32601) {
          this.$message("wrong network");
        } else {
          this.$message(e.message);
        }
      }
      loading.close();
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
body {
  background-color: rgb(245, 243, 243);
  height: 100vh;
}
.header {
  height: 60px;
  width: 100vw;
  background-color: #668b66;
  box-shadow: 0 1px 1px rgba(100, 100, 100, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left-h {
  height: 40px;
  display: flex;
  margin-left: 5vw;
}
.logo {
  height: 40px;
}
.logo-name {
  font-size: 30px;
  line-height: 40px;
  margin-left: 1vw;
}
.version{
  line-height: 60px;
}
.right-h {
  margin-right: 7vw;
}
/* .c-container {
  height: 60vh;
  width: 100vw;
  margin-top: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.panel {
  height: 450px;
  width: 600px;
  padding: 40px 50px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12) ; 
  border-radius: 5px;
}
.content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
.sbm-btn {
  margin-left: 440px;
  margin-top: 50px;
} */
</style>
