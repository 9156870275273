import {ethers} from 'ethers'
import pbwallet from 'pbwallet'
// import xcc_403 from '../phdata/xcc-403.json'
import {request} from './network/request'

let bsc = {}
let a = null
const coin_types = {
    'XCH': 1,
    'HDD': 2,
    'XCC': 3
}

function fix_uri(uri){
    if(uri.startsWith('ipfs:')){
        console.log('ipfs uri', uri)
        return uri.replace('ipfs://','https://ipfs.io/ipfs/')
    }else{
        return uri
    }

}


//读取钱包中pbt信息集合
async function load_pbts(){
    const pbts = []
    const cnt = await bsc.ctrs.pbt.balanceOf(bsc.addr)
    for(var i=0;i<cnt;i++){
        try{
            const pbt = await bsc.ctrs.pbt.tokenOfOwnerByIndex(bsc.addr,i)
            const pidx = pbt.toNumber()
            if(pidx){
                const uri = await bsc.ctrs.pbt.tokenURI(pidx)
                const resp = await fetch(fix_uri(uri))
                const info = await resp.json()
                // console.log('info', info)
                pbts.push({
                    id: pidx,
                    uri: await bsc.ctrs.pbt.tokenURI(pidx),
                    img: fix_uri(info.image)
                })
                console.log('执行load');
            }
        }catch(e){
            console.log('error when loading pbt', e)
        }
    }
    console.log('pbts:',pbts);
    return pbts
}
//获取钱包中pbt的数量
async function get_pbt_blance(){
    let blc = await bsc.ctrs.pbt.balanceOf(bsc.addr)
    return blc.toNumber()
}
//发送pbt
async function pbt_transfer(from,to,pbtid){
    if(!to){
        alert ('请输入正确地址');
        return false
    }else{
        await bsc.ctrs.pbt.transferFrom(from,to,pbtid)
    }
}

//连接钱包
async function connect() {
    request({
        url:'http://192.168.9.109:3103/lapi/addr-list/xcc.json',
        method:"get"
    }).then(res=>{a=res.data;console.log("res:",a)}).catch(err=>{
        console.log('err:',err);
    })
    bsc = await pbwallet.connect(true)
    if (bsc) {
        console.log('连接钱包',bsc);
        return bsc.addr
    }
    return false
}
//铸造pbt
async function mint_pbt(){
    const mres = await bsc.ctrs.pbt.mintFee()
    const options = {}
    // console.log(mres)
    if(mres[0]==ethers.constants.AddressZero) {
        //TODO: ethers.js compare with address 0
        // use BNB for fee
        options.value = mres[1]
    }else{
        const coin = new ethers.Contract(mres[0], pbc_abi, bsc.signer)   // TODO: should use ERC20 ABI
        const allow = await coin.allowance(bsc.addr, bconst.pbt_address)
        if(mres[1].gt(allow)){
            console.log('mres-allow',mres[1], allow)
            const apres = await coin.approve(bconst.pbt_address, mres[1].mul(10000))
            console.log('approve receipt', apres)
            //TODO: we need to wait until approve done
            return
        }
    }
    const res = await bsc.ctrs.pbt.mint(options)
    
    // console.log('mint receipt', res)
}
//获取pbt铸造上限
async function get_pbt_mintlimit(){
    const info = {}
    const limit = await bsc.ctrs.pbt.mintables()
    const supply = await bsc.ctrs.pbt.totalSupply()
    info.pbtlimit = limit.toNumber(),
    info.pbtsupply = supply.toNumber()
    return info
}
//设置pbt铸造上限
async function set_pbt_mintlimit(setnum){
    if(setnum==null){
        alert('请输入正确数字')
    }else{
        let bigsetnum = ethers.BigNumber.from(setnum)
        console.log(bigsetnum);
        const res = await bsc.ctrs.pbt.setMintLimit(bigsetnum)
        // console.log("设置上限：",res);
    }
    
}
//读取钱包中pbx信息集合
async function load_pbxs(){
    const prefix = a.prefix.toLowerCase()
    const pbxs = []
    const cnt = await bsc.ctrs.pbx.balanceOf(bsc.addr)
    for(var i=0;i<cnt;i++){
        try{
            const pbx = await bsc.ctrs.pbx.tokenOfOwnerByIndex(bsc.addr,i)
            const pidx = pbx.toNumber()
            if(pidx){
                const uri = await bsc.ctrs.pbx.tokenURI(pidx)
                const resp = await fetch(fix_uri(uri))
                const info = await resp.json()
                const dep_ph = await bsc.ctrs.pbx.getInfo(pidx)
                const dep_add = window.ChiaUtils.puzzle_hash_to_address(dep_ph[1], prefix)
                // console.log('dep_ph:', dep_ph[1],'address:',dep_add,prefix)
                // console.log('idx:',);
                pbxs.push({
                    id: pidx,
                    uri: await bsc.ctrs.pbx.tokenURI(pidx),
                    img: info.image,
                    dadrs: dep_add,
                    // tsnumber: idx.toNumber()
                })
            }
        }catch(e){
            console.log('error when loading pbx', e)
        }
    }
    console.log('pbxs:',pbxs);
    return pbxs
}
//铸造json中的pbx
async function mint_predefined_pbx(idx) {
    const prefix = a.prefix.toUpperCase()
    const cointype = coin_types[prefix]
    console.log('cointype:',cointype,prefix);
    const ph = a.puzzleHashes[idx]
    const fp = parseInt(a.fingerPrint)
    await bsc.ctrs.pbx.mint(cointype,fp,idx,ph)
}
//读取json中的pbx信息
async function load_predefined_pbx(){
    let pre_pbx_list = []
    pre_pbx_list.push({
        fp:a.fingerPrint,
        pref:a.prefix,
        ph:a.puzzleHashes
    })
    // console.log('a:',a);
    // console.log('pre_pbx-list',pre_pbx_list);
    return pre_pbx_list
}
//获取pbx的ctype,fp,index
async function get_pbxlist_info(pbxid){
    const res = await bsc.ctrs.pbx.getInfoList(pbxid)
    console.log('已经铸造',res);
    return res
}

//pbt绑定pbx
async function pb_connect(addressfrom,pbxid,pbtid){
        try{
            pbtid = ethers.BigNumber.from(pbtid)
            pbtid = ethers.utils.hexZeroPad(ethers.utils.hexValue(pbtid),32);
        }catch(e){
            console.log(e);
        }
    if((!pbtid)&&(!pbxid)){
        alert ('请输入正确pbt或pbx的id');
        return false
    }else{
        const pbconn_add = bsc.ctrs.pbconnect.address
        console.log('from:',addressfrom,'to:',pbconn_add,'pbxid:',pbxid,'pbtid:',pbtid);
        const res=await bsc.ctrs.pbx["safeTransferFrom(address,address,uint256,bytes)"](addressfrom,pbconn_add,pbxid,pbtid)
        // console.log('pbcon_receipt:',res);
    }   
}
//查询绑定pbx信息
async function get_info(pbtid){
    if(!pbtid){
        alert ('请输入正确pbt-id');
        return false
    }else{
        pbtid = parseInt(pbtid)
        const res = await bsc.ctrs.pbconnect["getPBXList(uint256)"](pbtid)
        console.log('res',res);
        alert (res)
    } 
}
//pbt解除绑定pbx
async function bind_retreat(pbxid){
    if(!pbxid){
        alert ('请输入需要解绑的pbx-id');
        return false
    }else{
        pbxid = parseInt(pbxid)
        await bsc.ctrs.pbconnect.retreat(pbxid)
        // ["retreat(uint256)"]
    }
}
//获取pbx已铸造数量
async function get_pbx_totalsupply(){
    const idx = await bsc.ctrs.pbx.totalSupply()
    return idx.toNumber()
}
export default {
    connect: connect,
    load_pbts: load_pbts,
    mint_pbt: mint_pbt,
    load_pbxs: load_pbxs,
    pbt_transfer:pbt_transfer,
    pb_connect:pb_connect,
    get_info:get_info,
    load_predefined_pbx:load_predefined_pbx,
    mint_predefined_pbx:mint_predefined_pbx,
    get_pbxlist_info:get_pbxlist_info,
    bind_retreat:bind_retreat,
    get_pbt_blance: get_pbt_blance,
    get_pbt_mintlimit:get_pbt_mintlimit,
    set_pbt_mintlimit:set_pbt_mintlimit,
    get_pbx_totalsupply:get_pbx_totalsupply
}

