<template>
  <div class="c-container">
    <div class="panel">
      <div class="content">
        <div class="info">{{ logninfo }}{{ User }}</div>
      </div>
      <div class="content">
        <div class="input-box">
          <el-input placeholder="请输入内容"  size="mini" v-model="input" clearable>
        </el-input>
        </div>
        <div>
          <el-button
            class="btn-box"
            type="success"
            size="mini"
            plain
            @click="setowner"
            >setowner</el-button
          >
          <el-button
            class="btn-box"
            type="success"
            size="mini"
            plain
            @click="setauditor"
            >setauditor</el-button
          >
        </div>
      </div>
      <div class="content">
        <div>
          Supply Amount: <span class="text">{{ supplyamount }}</span>
        </div>
        <el-button class="btn-box" type="success" size="mini" plain
          >编辑</el-button
        >
      </div>
      <div class="content">
        <div>
          Max Supply: <span class="text">{{ maxsupply }}</span>
        </div>
        <el-button class="btn-box" type="success" size="mini" plain
          >编辑</el-button
        >
      </div>
      <div class="content">
        <div>
          HotWalletMax: <span class="text">{{ hotwalletmax }}</span>
        </div>
        <el-button class="btn-box" type="success" size="mini" plain
          >编辑</el-button
        >
      </div>
      <div class="content">
        <div>
          HotWalletMin: <span class="text">{{ hotwalletmin }}</span>
        </div>
        <el-button class="btn-box" type="success" size="mini" plain
          >编辑</el-button
        >
      </div>
      <div class="content">
        <div>
          SingleTransIn: <span class="text">{{ singletransin }}</span>
        </div>
        <el-button class="btn-box" type="success" size="mini" plain
          >编辑</el-button
        >
      </div>
      <div class="content">
        <div>
          SingleTransOut: <span class="text">{{ singletransout }}</span>
        </div>
        <el-button class="btn-box" type="success" size="mini" plain
          >编辑</el-button
        >
      </div>
      <div class="sbm-btn">
        <el-button type="info" round @click="save">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import wops from "../wallet";

export default {
  name: "Panel",
  data() {
    return {
      authority: "Lord",
      input: '',
      supplyamount: 100000,
      maxsupply: 1000000,
      hotwalletmax: 999999999999999,
      hotwalletmin: 999999999999999,
      singletransin: 999999999999999,
      singletransout: 999999999999999,
    };
  },
  computed: mapState({
    Auditor:'Auditor',
    Owner:'Owner',
    User:'User',
    logninfo: function () {
      switch (this.User) {
        case null:
          return "Please Logn In";
          break;
        case this.Auditor:
          return "Auditor :";
          break;
        case this.Owner:
          return "Owner :";
          break;
        default:
          return "Visitor :";
      }
    },
  }),
  methods: {
    setowner: async function () {
      try {
        if(!this.input) {
          alert ('请输入正确钱包地址')
        }else {
          const addr = this.input;
          await wops.set_owner(addr);
        }
      } catch (err) {
        console.log(err);
      }
    },
    setauditor: async function () {
      try {
        if(!this.input) {
          alert ('请输入正确钱包地址')
        }else {
          const addr = this.input;
          await wops.set_auditor(addr);
        }
        
      } catch (err) {
        console.log(err);
      }
    },
    save: function(){
      console.log(this.input);
    }
  },
};
</script>

<style>
.c-container {
  height: 60vh;
  width: 100vw;
  margin-top: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.panel {
  height: 450px;
  width: 600px;
  padding: 40px 50px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}
.info {
  margin: 0 auto;
  color: #86B880;
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
.input-box {
  width: 300px;
}
.sbm-btn {
  margin-left: 440px;
  margin-top: 20px;
}
</style>