<template>
  <div class="c-container">
    <div class="panel">
      <div class="pbt">
        <div class="content" v-for="(item,index) in pbts" :key="index" @click="sel(item,index)">
          <div class="pbt-item">
            <p><span class="text">#{{ item.id }}</span></p>
            <img v-lazy="item.img" :class="{'active':index == current}">
          </div>
        </div>
      </div>
      <h4>铸造PBT:</h4>
      <el-button @click="mint" type="info">Mint PBT</el-button>
      <h4>发送PBT:</h4>
      <div class="content">
        <!-- <el-button @click="mint" type="info">Mint PBT</el-button> -->
        <el-input v-model="transaddr" placeholder="发送地址"></el-input>
        <el-button @click="transfer" type="info">发送</el-button>
      </div>
      <div class="content" v-if="mintlimit==null">
        <div>
          <el-tag class="tag" type="success" effect="dark">可铸造:(请读取)</el-tag>
          <el-tag class="tag" type="success" effect="dark">已铸造:(请读取)</el-tag>
        </div>
        <el-button @click="get_limit" type="info">获取铸造上限</el-button>
      </div>
      <div class="content" v-else>
        <div>
          <el-tag class="tag" type="success" effect="dark">可铸造:{{mintlimit}}</el-tag>
          <el-tag class="tag" type="success" effect="dark">已铸造:{{totalsupply}}</el-tag>
        </div>
        <!-- <el-button @click="get_limit" type="info">获取铸造上限</el-button> -->
      </div>
      <div class="content">
        <el-input v-model="mintlimit_set" placeholder="设置铸造上限"></el-input>
        <el-button @click="set_limit" type="info">设置</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import wops from "../wallet";
import { mapState } from "vuex";

export default {
  name: "PBTPanel",
  data() {
    return {
        transaddr:'',
        mintlimit:null,
        totalsupply:null,
        mintlimit_set:'',
        pbtblc:null,
        selpbt:null,
        current:0
    };
  },
  computed: mapState({
    pbts: "pbts",
    User: "User"
  }),
  // watch:{
  //   "$store.state.pbts.length":{
  //     async handler(){
  //         this.$store.commit('setPBTs', await wops.load_pbts())
  //         console.log('执行更新',this.pbts.length);
  //     },
  //     deep: true,
  //   } 
  // },
  // updated: async function () {
  //   this.$nextTick(async function(){
  //      this.pbtblc = await wops.get_pbt_blance()
  //       console.log("pbt数量",this.pbtblc);
  //   })
  // },
  // mounted() {
  //   this.$nextTick(function(){
  //     get_limit()
  //   })

  // },
  methods:{
    // getlength: async function(){
    //   this.pbtblc = await wops.get_pbt_blance()
    // },
    mint: async function(){
        console.log('try mint')
        await wops.mint_pbt()
        // await this.getlength()
    },
    sel(item,index) {
      console.log(item.id);  
      this.selpbt = item.id   
      this.current = index
      console.log('cur:',this.current);
      return this.selpbt   
    },
    transfer: async function(){
      const pbtid = this.selpbt
      // console.log(pbtid);
      // console.log('发送nft',this.User,this.transaddr,pbtid);
      // console.log(1);
      await wops.pbt_transfer(this.User,this.transaddr,pbtid)
      // console.log(2);
    },
    get_limit:async function(){
     const info = await wops.get_pbt_mintlimit()
     this.mintlimit = info.pbtlimit
     this.totalsupply = info.pbtsupply
    },
    set_limit:async function(){
      let setnum = parseInt(this.mintlimit_set) 
      await wops.set_pbt_mintlimit(setnum)
    }
  },
};
</script>

<style scoped>
.c-container {
  height: 80vh;
  width: 100vw;
  margin-top: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.active {
  border: red solid 1px;
}
.panel {
  height: 800px;
  width: 1000px;
  padding: 40px 50px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  overflow: scroll;
}
.info {
  margin: 0 auto;
  color: #86B880;
}
.pbt {
  display: flex;
  overflow-x: scroll;
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
img {
  width: 150px;
  height: 150px;
}
h4 {
  margin: 15px 0;
}
.tag {
  margin: 0 10px;
}
</style>
