import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import '../theme/index.css'
import store from './store'
import VueLazyLoad from 'vue-lazyload'

Vue.use(VueLazyLoad,{
  preLoad: 1,
  loading: require('./assets/loading.png'),
  error:require('./assets/loading.png')
})

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
