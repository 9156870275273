<template>
  <div class="tab">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="PBT" name="first"><PBTPanel/></el-tab-pane>
        <!-- <el-tab-pane label="PBX" name="second"><PBXPanel/></el-tab-pane>
        <el-tab-pane label="XCPANEL" name="third"><Panel/></el-tab-pane> -->
      </el-tabs>
    </div>
</template>

<script>
import Panel from './Panel'
import PBTPanel from './PBTPanel'
import PBXPanel from './PBXPanel'

export default {
    name: "Content",
    components:{
      Panel,
      PBTPanel,
      PBXPanel
    },
    data() {
      return {
        activeName: 'first',
      };
    },
    methods: {
      handleClick(tab) {
        // console.log(tab.name);
      }
    }
}
</script>

<style>
</style>