<template>
    <div class="c-container">
      <div class="panel">
        <!-- <el-select v-model="value" clearable placeholder="请选择币种">
          <el-option v-for="(item,index) in coin" :key="index" :label="item" :value="item"></el-option>
        </el-select> -->
        <div class="content" v-for="(item,index) in pbxs" :key="index">
          <div>
            <p><span class="text">ID:{{ item.id }}</span></p>
             <p><span class="text">address:{{ item.dadrs.substr(0,7) }}...{{ item.dadrs.substr(-4,4) }} <i @click="copy_address(item.dadrs)" class="el-icon-document-copy"></i></span></p>
          </div>
          <img :src="item.img">
        </div>
        <h4>读取PBX列表:</h4>
          <el-button @click="load_pre_pbx" type="info">LOAD PBX-INFO</el-button>
        <div class="content" v-if="this.ph==null">
          <el-tag type="info" >fp:(请读取)</el-tag>
          <el-tag type="info" >c-type:(请读取)</el-tag>
          <el-tag type="info" >已铸造:(请读取)</el-tag>
          <el-tag type="success" effect="dark">可铸造:(请读取)</el-tag>
        </div>
        <div class="content" v-else>
          <el-tag type="info" >fp:{{this.fp}}</el-tag>
          <el-tag type="info" >c-type:{{this.pref}}</el-tag>
          <el-tag type="info" >已铸造:{{this.pbxsupply}}</el-tag>
          <el-tag type="success" effect="dark">可铸造:{{this.ph.length - this.pbxsupply}}</el-tag>
        </div>
        <h4>铸造PBX:</h4>
        <el-button @click="mint_pre_pbx" type="info">Mint PBX</el-button>
        <div class="content_item">
          <!-- <el-input v-model="depaddr" placeholder="存款地址"></el-input>
          <el-input v-model="fingerprint" placeholder="finger-print"></el-input> -->
          <!-- <el-button @click="mint_pre_pbx" type="info">Mint PBX</el-button> -->
        </div>
        <h4>PBT绑定PBX:</h4>
        <div class="content_item">
          <el-input v-model="pbtid" placeholder="PBT tokenId"></el-input>
          <el-input v-model="conn_pbxid" placeholder="PBX tokenId"></el-input>
          <el-button @click="pbconnect" type="info">PBT绑定PBX</el-button>
          <el-button @click="getinfo" type="info">查询绑定</el-button>
        </div>
        <h4>PBT解绑PBX:</h4>
        <el-input v-model="r_pbxid" placeholder="PBX tokenId"></el-input>
        <div class="content_item">
          <el-button @click="retreat" type="info">解除绑定</el-button>
        </div>
      </div>
    </div>
</template>

<script>
import wops from "../wallet";
import { mapState } from "vuex";

export default {
  name: "PBXPanel",
  data() {
    return {
        depaddr: '',
        fingerprint:'',
        pbtid:'',
        conn_pbxid:'',
        r_pbxid:'',
        coin: ['XCH','HDD','XCC'],
        value:'',
        fp:null,
        pref:null,
        ph:null,
        pbxsupply:null,
        pbxid:[],//已铸造的pbx的id
    };
  },
  computed: mapState({
    pbxs: "pbxs",
    baddr: "baddr",
    pre_pbx:"pre_pbx",
    // pbx_list_info:"pbx_list_info",//已铸造的PBX的信息
  }),
  // watch:{
  //   "this.pbxs.length":{
  //     async handler(){
  //       this.$store.commit('setPBXs', await wops.load_pbxs())
  //       this.$store.commit('setPRE_PBX',await wops.load_predefined_pbx())
  //       console.log('执行监听',this.pbts);
  //     },
  //     deep: true,
  //   } 
  // },
  methods:{
    //复制pbx钱包地址
    copy_address:function(adrs){
      console.log(adrs);
    },
    //铸造pbx
    mint_pre_pbx:async function(){
      const pre_pbx = this.pre_pbx[0]
      const total_sup = await wops.get_pbx_totalsupply()
      this.pbxsupply = total_sup
      this.ph = pre_pbx.ph
        for(let i =1;i<this.ph.length-1;i++){
          if (i=total_sup){
            console.log('返回i',i);
            let idx = i+1
            console.log('idx',idx);
            await wops.mint_predefined_pbx(idx)
            return
          }
        }
    },
    //读取pbx.json的信息
    load_pre_pbx:async function(){
      const pre_pbx = this.pre_pbx[0]
      const total_sup = await wops.get_pbx_totalsupply()
      this.pbxsupply = total_sup
      this.fp = pre_pbx.fp
      this.pref = pre_pbx.pref
      this.ph = pre_pbx.ph
    },
    //pbt绑定pbx
    pbconnect:async function(){
      const pbxid = this.conn_pbxid;
      const pbtid = this.pbtid;
      await wops.pb_connect(this.baddr,pbxid,pbtid)
    },
    //pbt绑定查询
    getinfo:async function(){
      await wops.get_info(this.pbtid)
    },
    //pbt解除绑定pbx
    retreat:async function(){
      await wops.bind_retreat(this.r_pbxid)
    }
  },
};
</script>

<style scoped>
.c-container {
  height: 60vh;
  width: 100vw;
  margin-top: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.panel {
  height: 450px;
  width: 600px;
  padding: 40px 50px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  overflow: scroll;
}
.info {
  margin: 0 auto;
  color: #86B880;
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
.content_item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 15px 0;
}
h4 {
  margin: 15px 0;
}
el-tag {
margin:0 15px;
}
</style>
